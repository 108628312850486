import { Injectable } from '@angular/core'
import { SettingsDbService } from "./settings.db.service"
import { QuoteOfTheDay } from "../../app.interfaces"
import { BehaviorSubject } from "rxjs"


const NATIVE_GEOCODER_ACTIVE: string = "NATIVE_GEOCODER_ACTIVE"
const USER_DENIED_GEOLOCATION: string = "USER_DENIED_GEOLOCATION"
const QUOTE_OF_THE_DAY: string = "QUOTE_OF_THE_DAY"
const START_PAGE_GPS_ACTIVE: string = "START_PAGE_GPS_ACTIVE"
const WEATHER_PAGE_GPS_ACTIVE: string = "WEATHER_PAGE_GPS_ACTIVE"
const APP_START_COUNTER: string = "APP_START_COUNTER"
const RATING_DISABLED: string = "RATING_DISABLED"


@Injectable({
    providedIn: 'root'
})
export class SettingsService {


    nativeGeocoderActiveSubject = new BehaviorSubject<boolean>(null)
    userDeniedGeolocationSubject = new BehaviorSubject<boolean>(false)
    weatherPageGpsActiveSubject = new BehaviorSubject<boolean>(true)


    constructor(
        private settingsDbService: SettingsDbService
    ) {
        this.getNativeGeocoderActive().then(() => {
        })
        this.getWeatherPageGpsActive().then(() => {
        })
    }


    async getNativeGeocoderActive(): Promise<boolean> {
        const value = await this.settingsDbService.getValue<boolean>(NATIVE_GEOCODER_ACTIVE)
        if (value !== this.nativeGeocoderActiveSubject.value) {
            this.nativeGeocoderActiveSubject.next(value)
        }
        return value
    }


    async setNativeGeocoderActive(value: boolean) {
        await this.settingsDbService.setValue<boolean>(NATIVE_GEOCODER_ACTIVE, value)
        if (value !== this.nativeGeocoderActiveSubject.value) {
            this.nativeGeocoderActiveSubject.next(value)
        }
    }


    async getUserDeniedGeolocation(): Promise<boolean> {
        const result = await this.settingsDbService.getValue<boolean>(USER_DENIED_GEOLOCATION)
        let value: boolean
        if (result === null) {
            value = false
        } else {
            value = result
        }
        if (this.userDeniedGeolocationSubject.value !== value) {
            this.userDeniedGeolocationSubject.next(value)
        }
        return value
    }


    async setUserDeniedGeolocation(value: boolean) {
        await this.settingsDbService.setValue<boolean>(USER_DENIED_GEOLOCATION, value)
        if (this.userDeniedGeolocationSubject.value !== value) {
            this.userDeniedGeolocationSubject.next(value)
        }
    }


    async getQuoteOfTheDay(): Promise<QuoteOfTheDay> {
        return this.settingsDbService.getValue<QuoteOfTheDay>(QUOTE_OF_THE_DAY)
    }


    async setQuoteOfTheDay(value: QuoteOfTheDay) {
        await this.settingsDbService.setValue<QuoteOfTheDay>(QUOTE_OF_THE_DAY, value, 60 * 60 * 6)
    }


    async delQuoteOfTheDay() {
        await this.settingsDbService.delValue(QUOTE_OF_THE_DAY)
    }


    // Gibt an, ob auf der Startseite Standorterkennung durchgeführt werden soll oder nicht.
    async getStartPageGpsActive(): Promise<boolean> {
        const result = await this.settingsDbService.getValue<boolean>(START_PAGE_GPS_ACTIVE)
        if (result === null) {
            return true
        } else {
            return result
        }
    }


    async setStartPageGpsActive(value: boolean) {
        await this.settingsDbService.setValue<boolean>(START_PAGE_GPS_ACTIVE, value)
    }


    // Gibt an, ob auf der Wetterseite Standorterkennung durchgeführt werden soll oder nicht.
    async getWeatherPageGpsActive(): Promise<boolean> {
        const result = await this.settingsDbService.getValue<boolean>(WEATHER_PAGE_GPS_ACTIVE)
        let value: boolean
        if (result === null) {
            value = true
        } else {
            value = result
        }
        if (this.weatherPageGpsActiveSubject.value !== value) {
            this.weatherPageGpsActiveSubject.next(value)
        }
        return value
    }


    async setWeatherPageGpsActive(value: boolean) {
        await this.settingsDbService.setValue<boolean>(WEATHER_PAGE_GPS_ACTIVE, value)
        if (this.weatherPageGpsActiveSubject.value !== value) {
            this.weatherPageGpsActiveSubject.next(value)
        }
    }


    async getAppStartCounter(): Promise<number> {
        const result = await this.settingsDbService.getValue<number>(APP_START_COUNTER)
        return result || 0
    }


    async setAppStartCounter(value: number) {
        await this.settingsDbService.setValue<number>(APP_START_COUNTER, value)
    }


    async incrAppStartCounter(): Promise<number> {
        const oldValue = await this.getAppStartCounter()
        const newValue = oldValue + 1
        await this.setAppStartCounter(newValue)
        return newValue
    }


    async getRatingDisabled(): Promise<boolean> {
        return !!(await this.settingsDbService.getValue<boolean>(RATING_DISABLED))
    }


    async setRatingDisabled(value: boolean) {
        await this.settingsDbService.setValue<boolean>(RATING_DISABLED, !!value)
    }

}
