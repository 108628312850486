import {Component} from '@angular/core'
import {Platform} from '@ionic/angular'
import {SettingsService} from "./services/settings/settings.service"
import {register as registerSwiper} from "swiper/element/bundle"
import {
    MW_STARTPAGE_BACKGROUND_COLOR,
    MW_STARTPAGE_BACKGROUND_IMAGE,
    MW_STARTPAGE_BACKGROUND_POSITION,
    MW_STARTPAGE_BACKGROUND_SIZE,
} from "./app.constants"


registerSwiper()


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    constructor(
        private platform: Platform,
        private settingsService: SettingsService,
        // private ngZone: NgZone,
    ) {
        this.initializeApp()  // Async
    }


    async initializeApp() {

        // Startseitenhintergrund einstellen
        if (navigator.onLine) {
            const root: any = document.querySelector(':root')
            const backgroundColor = localStorage.getItem(MW_STARTPAGE_BACKGROUND_COLOR)
            if (backgroundColor) {
                root.style.setProperty("--startpage-background-color", backgroundColor)
            }
            const backgroundImage = localStorage.getItem(MW_STARTPAGE_BACKGROUND_IMAGE)
            if (backgroundImage) {
                const response = await fetch(backgroundImage)
                const objectUrl = URL.createObjectURL(await response.blob())
                root.style.setProperty("--startpage-background-image", `url(${objectUrl})`)
            }
            const backgroundPosition = localStorage.getItem(MW_STARTPAGE_BACKGROUND_POSITION)
            if (backgroundPosition) {
                root.style.setProperty("--startpage-background-position", backgroundPosition)
            }
            const backgroundSize = localStorage.getItem(MW_STARTPAGE_BACKGROUND_SIZE)
            if (backgroundSize) {
                root.style.setProperty("--startpage-background-size", backgroundSize)
            }
        }

        // // Taboola JavaScript dynamisch laden
        // await this.platform.ready()
        // if (environment.taboolaActivated) {
        //     this.ngZone.runOutsideAngular(() => {
        //
        //         eval(`
        //             window._taboola = window._taboola || []
        //             _taboola.push({article: 'auto'})
        //             !function (e, f, u, i) {
        //                 if (!document.getElementById(i)) {
        //                     e.async = 1
        //                     e.src = u
        //                     e.id = i
        //                     f.parentNode.insertBefore(e, f)
        //                 }
        //             }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/mobilewetterapp-network/loader.js', 'tb_loader_script')
        //             if (window.performance && typeof window.performance.mark == 'function') {
        //                 window.performance.mark('tbl_ic')
        //             }
        //         `)
        //
        //         console.debug("Taboola loaded.")
        //
        //     })
        // }

        if (!this.platform.platforms().includes("capacitor")) {
            // In DB schreiben, dass NativeGeocoder nicht funktionieren wird
            await this.settingsService.setNativeGeocoderActive(false)
        }

        // Protokollieren, wie oft die App bereits geöffnet wurde
        await this.settingsService.incrAppStartCounter()

    }

}
